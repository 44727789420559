/* eslint-disable */
import PermissionChecker from '@/security/permission-checker';

export class CaptainPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.read = permissionChecker.match('viewCaptains');
    this.edit = permissionChecker.match('editCaptain');
    this.destroy = permissionChecker.match('deleteCaptain');
    this.changeStatus = permissionChecker.match('changeCaptainStatus')
    this.print = permissionChecker.match('printCaptainsTable');
    this.export = permissionChecker.match('exportCaptainsTable');
    this.sendNotification = permissionChecker.match('sendNotification');
  }
}
