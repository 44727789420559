<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row mt-8">
      <h2 class="text-lg font-medium">{{ i18n('captains.title') }}</h2>
    </div>
    <!-- BEGIN: HTML Table Filter -->
    <TableFilter
      :disabled="loading"
      :filter-fields="filterFields"
      @filter="doFilter"
      @reset="doResetFilter"
    ></TableFilter>
    <!-- END: HTML Table Filter -->
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div
        class="flex flex-col sm:flex-row sm:items-end xl:items-start justify-end"
      >
        <div class="flex sm:mt-0 gap-2 sm:flex-nowrap flex-wrap">
          <app-action-toolbar
            @onPrint="onPrint()"
            @onExportXlsx="onExportXlsx()"
            @onExportAllXlsx="onExportAllXlsx()"
            @onDownloadPdf="onDownloadPdf()"
            :printPermission="hasPermissionToPrint"
            :exportPermission="hasPermissionToExport"
          ></app-action-toolbar>
          <div
            v-if="hasPermissionToDestroy || hasPermissionToChangeStatus"
            class="dropdown w-1/2 sm:w-auto flex-1 sm:flex-initial"
          >
            <button
              class="btn btn-outline-secondary w-full sm:w-auto dark:text-white"
              aria-expanded="false"
              :disabled="selected.length == 0"
              :class="selected.length > 0 ? 'dropdown-toggle' : ''"
            >
              {{ i18n('common.table.actions') }}
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2 sm:mr-2" />
            </button>
            <div class="dropdown-menu w-40">
              <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                <a
                  v-if="hasPermissionToChangeStatus"
                  href="javascript:;"
                  @click="doEnableAllSelected()"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  ><UnlockIcon class="w-4 h-4 mr-1 ml-1" />
                  {{ i18n('common.activate') }}</a
                >
                <a
                  v-if="hasPermissionToChangeStatus"
                  href="javascript:;"
                  @click="doDisableAllSelected()"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  ><LockIcon class="w-4 h-4 mr-1 ml-1" />
                  {{ i18n('common.disable') }}</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="relative">
        <loading-spinner
          type="half-circle"
          :duration="1000"
          :size="40"
          color="#4469A8"
          :loading="loading"
        />
        <div class="overflow-x-auto">
          <table
            ref="tableRef"
            class="table table-report sm:mt-2"
            id="userTable"
          >
            <thead>
              <tr>
                <th class="whitespace-nowrap">#</th>
                <th class="whitespace-nowrap text-center" id="ignore-1">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="selectAll"
                    @click="select"
                  />
                </th>
                <th
                  v-for="col in columns"
                  :key="col.name"
                  :id="
                    col.name === 'action'
                      ? 'ignore-2'
                      : col.name === 'avatar'
                      ? 'ignore-3'
                      : col.name === 'license'
                      ? 'ignore-8'
                      : col.name === 'vehicleLicense'
                      ? 'ignore-9'
                      : ''
                  "
                  class="text-center whitespace-nowrap"
                >
                  <span>{{ i18n(col.label) }}</span>
                </th>
              </tr>
            </thead>
            <tbody v-if="rows.length">
              <tr
                v-for="(row, index) in rows"
                :key="index"
                class="intro-x"
                :class="{ removedRow: row.isRemoved }"
              >
                <td class="w-40">
                  <div class="text-gray-600 text-sm whitespace-nowrap mt-0.5">
                    {{ +row.code.slice(1) + 1 }}
                  </div>
                </td>
                <td id="ignore-4">
                  <div class="text-center">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      :value="row.id"
                      v-model="selected"
                    />
                  </div>
                </td>
                <td class="table-report__action w-56" id="ignore-6">
                  <div
                    class="flex justify-center items-center gap-3 text-theme-24"
                  >
                    <router-link
                      class="text-theme-21 dark:text-theme-30 flex items-center gap-2"
                      :to="`/captains/${row.id}`"
                    >
                      <EyeIcon class="w-4 h-4" /> {{ i18n('common.view') }}
                    </router-link>
                    <a
                      v-if="hasPermissionToSendNotification"
                      href="javascript:;"
                      data-toggle="modal"
                      data-target="#send-notification-modal"
                      @click=";(selectedId = row.id), (selectedUser = row)"
                      class="text-theme-37 flex items-center gap-2"
                    >
                      <SendIcon class="w-4 h-4" /> {{ i18n('common.send') }}
                    </a>
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenter(row, 'code') }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenter(row, 'fullName') }}
                  </div>
                </td>

                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ row.city ? (isRTL ? row.city.ar : row.city.en) : 'ـــ' }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenterInteger(row, 'closedOrdersCount') }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenterInteger(row, 'cancelledOrdersCount') }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenterDate(row, 'createdAt') }}
                  </div>
                  <div class="font-small text-xs whitespace-nowrap mt-1">
                    {{ presenterTime(row, 'createdAt') }}
                  </div>
                </td>
                <td class="w-40">
                  <div
                    class="flex items-center justify-center"
                    :class="{
                      'text-theme-24': !row.isEnabled,
                      'text-green dark:text-theme-30': row.isEnabled
                    }"
                  >
                    <CheckSquareIcon class="w-4 h-4 mr-2 ml-2" />
                    {{
                      row.isEnabled
                        ? i18n('common.activated')
                        : i18n('common.disabled')
                    }}
                  </div>
                </td>
                <td class="text-center">
                  <div
                    class="font-medium whitespace-nowrap"
                    style="direction: ltr !important"
                  >
                    {{ presenter(row, 'phoneNumber') }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    <CheckSquareIcon
                      class="w-6 h-6 mr-2 ml-2 text-green"
                      v-if="isThere(row, 'license')"
                    />
                    <XSquareIcon
                      class="w-6 h-6 mr-2 ml-2 text-theme-24"
                      v-else
                    />
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    <CheckSquareIcon
                      class="w-6 h-6 mr-2 ml-2 text-green"
                      v-if="isThere(row, 'vehicleLicense')"
                    />
                    <XSquareIcon
                      class="w-6 h-6 mr-2 ml-2 text-theme-24"
                      v-else
                    />
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenterLocalization(row.vehicle, 'name') }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenterPrice(row?.walletAmount || 0) }}
                  </div>
                </td>
                <td class="text-center">
                  <Tippy
                    tag="div"
                    class="font-medium whitespace-nowrap truncate w-40"
                    :content="row.dashboardReview?.review"
                  >
                    {{ row.dashboardReview?.review }}</Tippy
                  >
                  <div
                    class="font-small text-xs whitespace-nowrap mt-1 text-gray-300 flex items-center justify-center"
                  >
                    <svg
                      aria-hidden="true"
                      class="w-5 h-5 text-yellow-400"
                      style="color: gold;"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <title>First star</title>
                      <path
                        d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                      ></path>
                    </svg>
                    ({{ row.dashboardReview?.rating || 0 }})
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <app-empty-page
            v-if="!rows.length && !loading"
            :label="i18n('empty.captain')"
            label-class="text-lg font-medium"
            icon-size="4x"
            class="box w-full py-64"
          ></app-empty-page>
        </div>
        <Pagination
          v-if="rows.length"
          :page="currentPage"
          :pageSize="pagination.limit"
          :rowsNumber="pagination.total"
          :showSizeChanger="true"
          :hasNext="hasNext"
          :hasPrev="hasPrev"
          :loading="loading"
          :disabled="!rows.length"
          position="center"
          @next-page="doNextPage"
          @prev-change="doPrevPage"
          @size-change="doChangePaginationPageSize"
          @page-change="goToPage"
        ></Pagination>
      </div>
    </div>
    <!-- END: HTML Table Data -->
    <!-- BEGIN: Modal -->
    <ActivateModal item="chef" />
    <DisableModal item="chef" />
    <SendNotificationModal
      :id="selectedId"
      type="captain"
      :user="selectedUser"
      @close=";(selectedId = null), (selectedUser = {})"
    />
    <!-- END: Modal -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ActivateModal from '@/components/modals/activate-modal.vue'
import DisableModal from '@/components/modals/disable-modal.vue'
import { CaptainPermissions } from './captain-permissions'
import SendNotificationModal from '@/components/modals/send-notification-modal.vue'
import { paginationMixin } from '@/mixins/pagination-mixin'
import { presenterMixin } from '@/mixins/presenter-mixin'
import { exportPrintMixin } from '@/mixins/export-print-mixin'

export default {
  mixins: [paginationMixin, presenterMixin, exportPrintMixin],
  components: {
    ActivateModal,
    DisableModal,
    SendNotificationModal
  },
  data() {
    return {
      // data important for Mixins
      fetchOptions: { type: 'captain' },
      tableRef: null,
      exportTableName: 'captains',
      exportResourceName: 'captain',
      excludedCols: [1, 9],
      ignoreElements: [
        'ignore-1',
        'ignore-2',
        'ignore-3',
        'ignore-4',
        'ignore-5',
        'ignore-6',
        'ignore-7',
        'ignore-8',
        'ignore-9',
        'ignore-10'
      ],
      // component Data
      selected: [],
      selectAll: false,
      selectedId: null,
      selectedUser: {},
      columns: [
        {
          name: 'action',
          field: 'action',
          label: 'common.actions',
          align: 'center'
        },
        {
          name: 'captainCode',
          field: 'captainCode',
          label: 'iam.fields.captainCode',
          align: 'center'
        },
        {
          name: 'fullName',
          field: 'fullName',
          label: 'iam.fields.fullName',
          align: 'center'
        },
        {
          name: 'captainCity',
          field: 'captainCity',
          label: 'iam.fields.city',
          align: 'center'
        },
        {
          name: 'closedOrders',
          field: 'closedOrders',
          label: 'iam.fields.closedOrders',
          align: 'center'
        },
        {
          name: 'cancelledOrders',
          field: 'cancelledOrders',
          label: 'iam.fields.cancelledOrders',
          align: 'center'
        },
        {
          name: 'createdAt',
          field: 'createdAt',
          label: 'common.createdAt',
          align: 'center'
        },
        {
          name: 'status',
          field: 'status',
          label: 'iam.fields.status',
          align: 'center'
        },
        {
          name: 'phoneNumber',
          field: 'phoneNumber',
          label: 'iam.fields.phoneNumber',
          align: 'center'
        },
        {
          name: 'license',
          field: 'license',
          label: 'iam.fields.license',
          align: 'center'
        },
        {
          name: 'vehicleLicense',
          field: 'vehicleLicense',
          label: 'iam.fields.vehicleLicense',
          align: 'center'
        },
        {
          name: 'vehicle',
          field: 'vehicle',
          label: 'iam.fields.vehicle',
          align: 'center'
        },
        {
          name: 'walletAmount',
          field: 'walletAmount',
          label: 'iam.fields.walletAmount',
          align: 'center'
        },
        {
          name: 'review',
          field: 'review',
          label: 'iam.fields.review',
          align: 'center'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      currentUser: 'auth/currentUser',
      rows: 'user/list/rows',
      pagination: 'user/list/pagination',
      listLoading: 'user/list/loading',
      hasNext: 'user/list/hasNext',
      hasPrev: 'user/list/hasPrev',
      destroyLoading: 'user/destroy/loading',
      exportLoading: 'exportStore/loading',
      saveLoading: 'user/form/saveLoading'
    }),
    hasPermissionToEdit() {
      return new CaptainPermissions(this.currentUser).edit
    },
    hasPermissionToDestroy() {
      return new CaptainPermissions(this.currentUser).destroy
    },
    hasPermissionToChangeStatus() {
      return new CaptainPermissions(this.currentUser).changeStatus
    },
    hasPermissionToPrint() {
      return new CaptainPermissions(this.currentUser).print
    },
    hasPermissionToExport() {
      return new CaptainPermissions(this.currentUser).export
    },
    hasPermissionToSendNotification() {
      return new CaptainPermissions(this.currentUser).sendNotification
    },
    loading() {
      return this.listLoading || this.destroyLoading || this.exportLoading
    },
    currentPage() {
      return this.pagination.currentPage || 1
    },
    rowIndex() {
      return index => {
        return index + this.pagination.limit * (this.currentPage - 1) + 1
      }
    },
    statusOptions() {
      return [
        {
          label: this.i18n('captains.enumerators.status.enabled'),
          value: 'enabled'
        },
        {
          label: this.i18n('captains.enumerators.status.rejected'),
          value: 'rejected'
        },
        {
          label: this.i18n('captains.enumerators.status.disabled'),
          value: 'disabled'
        },
        {
          label: this.i18n('captains.enumerators.status.pendingUpdate'),
          value: 'pendingUpdate'
        },
        {
          label: this.i18n('captains.enumerators.status.pendingAdd'),
          value: 'pendingAdd'
        }
      ]
    },
    filterFields() {
      return [
        {
          label: this.i18n('iam.fields.captainCode'),
          value: 'code',
          type: 'text'
        },
        {
          label: this.i18n('captains.filter.fullName'),
          value: 'fullName',
          type: 'text'
        },
        {
          label: this.i18n('iam.fields.city'),
          value: 'city',
          type: 'text',
          localize: true
        },
        {
          label: this.i18n('iam.fields.closedOrders'),
          value: 'closedOrdersCount',
          type: 'number-range'
        },
        {
          label: this.i18n('iam.fields.cancelledOrders'),
          value: 'cancelledOrdersCount',
          type: 'number-range'
        },
        {
          label: this.i18n('captains.filter.phoneNumber'),
          value: 'phoneNumber',
          type: 'text'
        },
        {
          label: this.i18n('iam.fields.joinDate'),
          value: 'createdAt',
          type: 'date-range'
        },
        {
          label: this.i18n('iam.fields.vehicleLicense'),
          value: 'vehicleLicense',
          type: 'listWithOperator',
          options: this.isExistOptions
        },
        {
          label: this.i18n('iam.fields.vehicle'),
          value: 'vehicle.name',
          type: 'text',
          localize: true
        },
        {
          label: this.i18n('iam.fields.license'),
          value: 'license',
          type: 'listWithOperator',
          options: this.isExistOptions
        },
        {
          label: this.i18n('iam.fields.review'),
          value: 'dashboardReview.rating',
          type: 'number-range'
        }
      ]
    },
    isExistOptions() {
      return [
        {
          label: this.i18n('common.no'),
          value: null,
          operator: 'equal'
        },
        {
          label: this.i18n('common.yes'),
          value: null,
          operator: 'notEqual'
        }
      ]
    }
  },
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.captains')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.captains')
    }
  },
  async mounted() {
    await this.doFetchFirstPage()
  },
  methods: {
    ...mapActions({
      doFetch: 'user/list/doFetch',
      doFetchNextPage: 'user/list/doFetchNextPage',
      doFetchPreviousPage: 'user/list/doFetchPreviousPage',
      doChangePaginationCurrentPage: 'user/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'user/list/doChangePaginationPageSize',
      doToggleUserAccountStatus: 'user/view/doToggleUserAccountStatus',
      doEnableAll: 'user/list/doEnableAllSelected',
      doDisableAll: 'user/list/doDisableAllSelected',
      doDestroyAll: 'user/destroy/doDestroyAll',
      doExport: 'exportStore/doExport'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    select() {
      this.selected = []
      if (!this.selectAll) {
        this.rows.forEach(element => {
          this.selected.push(element.id)
        })
      }
    },
    clearSelection() {
      this.selected = []
      this.selectAll = false
    },
    async doEnableAllSelected() {
      await this.doEnableAll(this.selected)
      this.clearSelection()
    },
    async doDisableAllSelected() {
      await this.doDisableAll(this.selected)
      this.clearSelection()
    },
    async doDestroyAllSelected() {
      await this.doDestroyAll(this.selected)
      this.clearSelection()
    }
  }
}
</script>
<style scoped lang="scss">
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}

.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}

.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}

.embed-pdf-scroll::-webkit-scrollbar {
  width: 1px !important;
  height: 1px !important;
  // color: red !important;
  // background: red;
  border-radius: 50px;
}
.embed-pdf-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
}
.embed-pdf-scroll::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background-color: black !important;
  // outline: 5px solid greenyellow !important;
}
</style>
